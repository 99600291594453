///* ========================================================================
//   WEEVER - STYLES FOR ICONS IN TABLE CELLS
//   ======================================================================== */

// todo: FM-8452: refactor for ITCSS pattern

.wx-td-icon-link {
  display: block;
  font-size: 1.5rem;
  margin: -0.625rem -1rem; // match cell properties
  padding: 0.625rem 1rem; // create a larger clickable area
  text-decoration: none;

  .wx-icon,
  .wx-datatable-icon {
    display: flex; // necessary to support css transform (animation)
    align-items: center;
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
  }

  // Specific individual icon behaviours
  .wx-icon-database-edit,
  .wx-icon-database-edit1 {
    font-size: 1.75rem;
  }

  &:hover {
    text-decoration: none;

    .wx-icon:before,
    .wx-datatable-icon:before {
      transform: scale(1.2);
      // filter: drop-shadow(1px 5px 2px rgba(0, 0, 0, 0.125));
    }

    .wx-icon-database-edit1:before {
      content: unicode('e91e'); // should match "wx-icon-database-edit" in wx-icons.scss to create a transition effect
    }
  }
}

// Legacy support for .wx-datable-icons that do not have a proper class wrapper yet
// See: https://weeverapps.atlassian.net/browse/FM-8478

.wx-datatable-icon {
  display: flex;
  align-items: center;
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;

  &:hover .c-folder__icon {
    transform: scale(1.2);
  }
}

.c-table,
.b-table {
  .c-folder__icon {
    top: -2px;
  }
}