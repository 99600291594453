/* ==========================================================================
   #BOOTSTRAP UTILITIES
   ========================================================================== */

/**
 * These classes are used by bootstrap plugins.
 */

.fade {
	opacity: 0;

	&.show {
		opacity: 1;
	}
}

.fade,
.show {
	transition: opacity 0.3375s;
}


