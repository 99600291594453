/* ==========================================================================
   #DISPLAY UTILITIES
   ========================================================================== */

// sass-lint:disable no-important

.u-block {
  display: block !important;
}

.u-inline-block {
  display: inline-block !important;
}

.u-inline {
  display: inline !important;
}

.u-hide,
.u-display-none {
  display: none !important; // prefer the more accessible "u-hidden-visually" in most cases
}
