/* ==========================================================================
   #USERFLOW.JS CUSTOM STYLES
   ========================================================================== */


/**
 * Custom styles for userflow.js
 * These styles modify additional DOM elements (buttons) that we create in orchestrator
 * which allow users to optionally minimize/hide the default userflow controls
 *
 */

 #minimizeUserflowButton {
    -webkit-appearance: none;
    background: initial;
    padding: 0;
    padding: initial;
    position: fixed;
    bottom: 90px;
    right: 20px;
    display: none;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin: 0px;
    z-index: 105;
    background-color: #fff;
    border: 2px solid #00467f;
    border-radius: 50%;
    color: #00467f;
    transition: opacity 0.5s ease-out;
}

#minimizeUserflowButton:hover {
    background-color: #00467f !important;
    color: #ffffff !important;
    border-color: #ffffff !important;
}

#dockedUserflowButton {
    -webkit-appearance: none;
    background: initial;
    padding: initial;
    background: initial;
    position: fixed;
    width: 200px;
    height: 27px;
    text-align: center;
    bottom: 281px;
    right: 0;
    display: none;
    justify-content: center;
    align-items: center;
    transform: rotate(-90deg);
    transform-origin: bottom right;
    font-size: 15px;
    font-weight: 600;
    line-height: 1;
    border: none;
    margin: 0px;
    padding: 5px 16px 6px 16px;
    border-radius: 4px 4px 0 0;
    cursor: pointer;
    z-index: 101;
    background-color: #00467f;
    color: #ffffff;

    > #dockerUserflowButton__text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}