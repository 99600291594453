/* ==========================================================================
   #POSITION UTILITIES
   ========================================================================== */

/**
 * Position
 */

.u-position-relative {
    position: relative;
}

.u-position-static {
    position: static;
}
