/* ==========================================================================
   #OVERFLOW UTILITIES
   ========================================================================== */

/**
 * Overflow
 */

.u-overflow-auto {
	overflow: auto;
}

.u-overflow-scroll {
	overflow: scroll;
}

.u-overflow-visible {
	overflow: visible;
}

.u-overflow-hidden {
	overflow: hidden;
}


/**
 * Horizontal Overflow
 */

.u-overflow-x-auto {
	overflow: auto;
}

.u-overflow-x-scroll {
	overflow: scroll;
}


/**
 * Vericall Overflow
 */

.u-overflow-y-auto {
	overflow: auto;
}

.u-overflow-y-scroll {
	overflow: scroll;
}
